<template>
  <el-container class="shopList">
    <el-header class="box add bb">
         <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>留样管理</el-breadcrumb-item>
       <el-breadcrumb-item>留样列表</el-breadcrumb-item>
    </el-breadcrumb>
        <div><el-button  icon="el-icon-plus"
          type="primary"  @click="Add()" size="medium">新增留样</el-button></div>
    </el-header>
    <el-main class="">
    <el-row class="box search mt20">
       <el-form :inline="true" :model="page" class="demo-form-inline">
        <el-col :span="4" class="mr10">
             <el-form-item size="small" >
         <el-input placeholder="食材名称" v-model="page.sample_name" clearable class="input-with-select" >
            </el-input>
        </el-form-item>
        </el-col>
        <el-col :span="4" class="mr10">
          <el-form-item size="small">
            <el-date-picker type="date" placeholder="留样时间" v-model="page.sample_time" :picker-options="pickerOptionsStart" style="width: 100%;" format="yyyy-MM-dd" value-format="yyyy-MM-dd" @change="change(page.sample_time)"></el-date-picker>
           
        </el-form-item>
        </el-col>
         <el-col :span="2" class="tl mr10">
           <el-form-item  size="small">
          <el-button  type="primary"  @click="onSubmit" class="general_bgc general_border cfff">查询</el-button>
        </el-form-item>
        </el-col>
      </el-form>
    </el-row>
    <div class="box list">
       <el-table border :data="tableData" style="width: 100%">
        <el-table-column type="index" label="序号" width="60" align='center'></el-table-column>
        <el-table-column prop="sample_name" min-width="150" :show-overflow-tooltip="true"  label="留样食材名称" align='center'></el-table-column>
       <el-table-column prop="sample_person"  min-width="150" :show-overflow-tooltip="true"  label="留样人" align='center'></el-table-column>
        <el-table-column prop="sample_time"  min-width="150" :show-overflow-tooltip="true"  label="留样时间" align='center' ></el-table-column>
        <el-table-column prop="food_sample_count"  min-width="150" :show-overflow-tooltip="true" label="留样数量（g）" align='center'>
            <template slot-scope="scope">
              <span>{{scope.row.sample_num!=null?scope.row.sample_num:'0'}}</span>
        </template>
        </el-table-column>
         <el-table-column  align='center' label="留样图片">
       <template slot-scope="scope">
            <img v-if="scope.row.accessory_list.length==0"  src="../../../assets/image/source.png"   style="width: 40px;height: 40px"/> 
         <div v-else>
            <el-image  style="width: 40px;height: 40px" :src="scope.row.accessory_list[0].accessory_url" :preview-src-list="[scope.row.accessory_list[0].accessory_url]" alt=""></el-image>
         </div>
      <!---->
     
    </template>
    </el-table-column>
        <el-table-column label="操作" width="130" fixed="right"  align='center'>
          <template slot-scope="scope">
               <el-button  type="text" class="orange disib"  size="mini"  @click="goUpdate(scope.row.sample_id)">编辑</el-button>
           <el-button type="text" class="red" size="medium" @click="godelete(scope.row.sample_id,scope.$index)">删除</el-button>
         </template>
        </el-table-column>
      </el-table>
      <div class="i-page fr disib mt20">
        <el-pagination
          background
          :page-size='10'
          layout="total,prev, pager, next"
          :total="total"
          :current-page="currentPage"
          @current-change = 'currentChange'>
        </el-pagination>
      </div>
    </div> 
    </el-main>
  </el-container>
</template>
<script>
import axios from '../../../util/api'
import { mapState } from 'vuex'
import { local,session} from "../../../util/util";
import {imageUrl} from '../../../config/index'
export default {
  data () {
    return {
        imageUrl:imageUrl,
      page: {
         pager_offset: "0",
      pager_openset: '10',
      sample_name:'',
      company_id:"",
      sample_time:''
      },
      currentPage: 1,
      total: 0,
      tableData:[],
       pickerOptionsStart: {
        disabledDate: time => {
          if (this.page.search_time_end) {
            return time.getTime() > new Date(this.page.search_time_end).getTime()
          }
        }
      },
      pickerOptionsEnd: {
        disabledDate: time => {
          if (this.page.search_time_bigen) {
            return time.getTime() < new Date(this.page.search_time_bigen).getTime() - 86400000
          }
        }
      },
    }
  },
    computed: {
    ...mapState({
      samplePage: (state) => state.sample.samplePage
    })
    
  },
   created () {
        // this.page.company_id =local.get('company_id')?String(local.get('company_id')):'';
     this.page = Object.assign({}, this.samplePage);
    this.currentPage = this.page.pager_offset / 10 + 1
    this.init(this.page)
  },
  methods: {
           init (params) {
      axios.get('/pc/sample/list', params).then((v) => {
        this.tableData=v.data.sample_list;
         this.total = v.data.pager_count
      })
    },
    Add(){
      session.set('params',{id:'',name:''})
      this.$router.push({path: 'sample/add'})
    },
    goUpdate(index){
      session.set('params',{id:index,name:''})
      this.$router.push({
        name: "SampleAdd",
      });
    },
    change(val){
      if (val == null) val = '';
        this.page.sample_time=val;
        this.onSubmit()
    },
     // 搜索
     onSubmit (val) { 
          if (val == null) val = '';
      this.currentPage = 1
      this.page.pager_offset = '0';
      
      this.$store.dispatch('sample/setNewPage', this.page)
      this.init(this.page)
    },
    // 分页
    currentChange(page){
      this.currentPage = page;
      this.page.pager_offset = String((page - 1) * 10)
      this.$store.dispatch('sample/setNewPage', this.page)
      this.init(this.page)
    },
   godelete(val,index){
     this.$confirm('确定删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(() => {
           axios.delete('/pc/sample/delete', {sample_id:String(val)}).then((v) => {
                this.tableData.splice(index, 1)
           })
        })
  }
  }
}
</script>
<style lang="scss" scoped>
.shopList{
  overflow-x: hidden;
  .add{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

 /deep/ .el-input-group__append .el-button--primary .el-icon-search{color: #fff}
}

</style>
